.placeholder {
    z-index: 5;
    height: 0px;
    margin-bottom: 290px;
    position: relative;

}

footer {
    height: 290px;
    width: 100%;
    margin: auto;
    z-index: -1;
    position: fixed;
    bottom: 0;
    padding: 2rem;
    /* 
    background: radial-gradient(circle, rgb(0, 0, 0) 25%, rgb(25, 25, 25) 75%);
    background-size: 500%;
    animation: gradient 15s ease infinite; 
    */
    background-color: rgb(15, 15, 15);
}

footer .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer .container img {
    width: 200px;
    cursor: pointer;
}

footer .container ul {
    color: white;
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-around;
    margin: 5px 0;
    flex-wrap: wrap;

}

footer ul li {
    font-size: 1.4rem;
    z-index: 2;
}

.copyright {
    height: 3vh;
    display: flex;
    justify-content: center;

}

.copyright p {
    font-size: 1.1rem !important;
    color: white;
    font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 620px) {
    footer .container ul {
        margin: 7px 0;

    }

    footer ul li {
        font-size: 1.2rem;
    }

    .placeholder {
        margin-bottom: 290px;

    }

    footer {
        height: 290px;
    }


}

@media screen and (max-width: 390px) {
    footer ul li {
        margin: 0px 20px;
    }

}