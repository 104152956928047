.header {
  position: fixed;
  height: 100px;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in;
  overflow: hidden;
}

.header-bg {
  background-color: rgba(0, 0, 0, 0.85);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .navbar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .navbar .logo img {
  width: 130px;
  height: auto;
}

.hamburger {
  display: none;
  margin-right: 1rem;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  position: relative;
  background-color: rgb(0, 0, 0, 0.7);
}

.fatimes,
.fabars {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header .navbar .nav-menu {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 1rem;
  z-index: 100;
}

.header .nav-menu li {
  margin-left: 1.5rem;
  color: #fff;
  font-size: 1.4rem;
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
}

.header .navbar .booking ul {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 30px;
  margin-left: 50px;
}

.header .navbar .booking ul li {
  text-transform: uppercase;
  font-family: "Chela One", "BalooBhaina-Regular";
  cursor: pointer;
  color: white;
  padding: 10px;
  font-size: 1.4rem;
  border-radius: 30px;
  background: radial-gradient(circle, #ffd401, #ff9100, #ff76d2, #5bc6f6 90%);
  background-size: 500%;
  animation: gradient 3s ease infinite;
  transition: 0.2s ease;
  width: 157px;
}

.header .navbar .booking ul li:hover {
  background: radial-gradient(circle, #5bc6f6, #ff76d2, #ff9100, #ffd401);
  background-size: 500%;
  animation: gradient 3s ease infinite;
  transition: 0.2s ease;
  border: 5px solid white;
  transition: 0.1s ease;
}

.header .navbar .cross {
  text-decoration: line-through;
  cursor: not-allowed;
}

.header .navbar button {
  width: 100px;
  margin-right: 1rem;
}

.header .navbar .hamburger .icon {
  font-size: 3rem;
  color: #fff;
}

.header .location {
  cursor: pointer;
}

@media screen and (max-width: 965px) {
  .header {
    max-width: 100%;
    /* border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
  }

  .header .navbar {
    overflow: hidden;
    max-width: 100%;
  }

  .header .navbar .hamburger {
    display: block;
    z-index: 1001;
    margin-right: 0;
  }

  .header .navbar .nav-menu .link-hover:after {
    bottom: 0px;
    height: 15px;
  }

  .header .navbar .nav-menu {
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 100%;
    z-index: 1000;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    transition: 0.3s ease;
  }

  .header .navbar .active {
    left: 0;
  }

  .link-hover:after {
    box-shadow: none;
  }

  .header .navbar .nav-menu li {
    overflow: hidden;
    padding: 0;
    margin: 0;
    margin-bottom: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 100px;
    border-radius: 5px;
    font-size: 3.5rem;
    text-shadow: none;
  }

  .header .navbar button {
    overflow: hidden;
    width: 200px;
    height: 100px;
    display: flex;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    background-color: var(--primary-dark);
    border: none;
    margin-right: 0;
  }

  .navbar ul .yellow {
    background-color: #ffd401;
  }

  .navbar ul .orange {
    background-color: #ff9100;
  }

  .navbar ul .green {
    background-color: #a3d827;
  }

  .navbar ul .pink {
    background-color: #ff76d2;
  }

  .navbar ul .blue {
    background-color: #5bc6f6;
  }
}

@media screen and (max-width: 620px) {
  .header .navbar .booking ul {
    gap: 10px;
    margin-left: 30px;
  }

  .header .navbar .booking ul li {
    padding: 10px 0px;
    font-size: 1rem;
    width: 107px;
  }

  .header .navbar .booking ul li:hover {
    border: 3px solid white;
  }
}

@media screen and (max-width: 475px) {
  .navbar {
    padding-right: 5px !important;
  }

  .header .navbar .booking ul {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-left: 5px;
  }

  .header .navbar .booking ul li {
    margin: 10px 0;
    padding: 10px 0px;
    font-size: 0.9rem;
    width: 90px;
  }

  .header .navbar .booking ul li:hover {
    border: 3px solid white;
  }

  .header .navbar .nav-menu li {
    width: 200px;
    height: 85px;
    margin-bottom: 1.1rem;
    font-size: 3rem;
  }

  .header .navbar button {
    font-size: 3rem;
    width: 175px;
    height: 85px;
  }

  .header .navbar .logo img {
    margin-top: 15px;
    width: 100px;
  }

  .header .navbar .hamburger .icon {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 400px) {
  .header .navbar .logo img {
    width: 90px;
  }

  .header .navbar .booking ul li:hover {
    border: 3px solid white;
  }
}
