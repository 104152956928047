.about,
.team {
  padding: 6rem 1rem;

  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: url("../../assets/background/cartoon-playground-bg.webp") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e1e1e;

  /* -webkit-box-shadow: rgba(0, 0, 0, .8) 0 7px 20px 10px;
	-moz-box-shadow: rgba(0, 0, 0, .8) 0 7px 20px 10px;
	box-shadow: rgba(0, 0, 0, .8) 0 7px 20px 10px; */
}

.team {
  background: url("../../assets/background/kids-playing-bg.webp") no-repeat center center/cover;
}

.about h1,
.team h1 {
  overflow: hidden;
  text-align: center;
}

.about p {
  color: white;
  font-size: 1.3rem;
  font-family: "Roboto", sans-serif;
  line-height: 25px;
}

.about .container .content h4 {
  font-size: 2.6rem;
  color: white;
  line-height: 50px;
  margin-bottom: 0.5rem;
  padding: 0px;
  border-bottom: 5px solid white;
}

.about .container .content h3 {
  font-size: 1.2rem;
  color: white;
}

.about .container {
  height: auto;
  gap: 40px;
  margin: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about .container .content {
  height: 330px;
  width: 500px;
  /* border-radius: 20px; */
  padding: 1.5rem;
}

.about .container .mission,
.about .container .facility {
  background: rgba(0, 0, 0, 0.8);
  /* transform: rotate(2deg); */
}

.about .container .img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about .container img {
  width: 495px;
  /* border-radius: 20px; */
  box-shadow: 0 0 20px 5px black;
}

/* ===================TEAM=================== */

.team .container {
  margin-top: 0;
}

.team .container .main-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
}

/* ===================CARDS=================== */

.team .card-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.team .card-container .card {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 8px 10px 7px 0px rgba(0, 0, 0, 0.8);
  margin: 20px;
  padding: 8px;
  border-radius: 5px;
}

.team .card-container .card .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.team .card-container .card .content .imgBox {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.team .card-container .card .content .imgBox img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.team .card-container .card .content .contentBox h2 {
  color: #fff;
  padding: 5px;
  text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  text-align: center;
}

.team .card-container .card .content .contentBox p {
  color: #fff;
  text-shadow: 0 0 1.2px black, 0 0 1.2px black, 0 0 1.2px black, 0 0 1.2px black;
  font-size: 1rem;
}

@media screen and (max-width: 1064px) {
  .team .container .main-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1100px) {
  .about p {
    font-size: 0.95rem;
  }

  .about .container {
    height: auto;
    gap: 20px;
    margin: 0px;
    padding: 10px;
  }

  .about .container img {
    width: 380px;
    height: 265px;
    box-shadow: 0 0 20px 5px black;
  }

  .about .container .content {
    height: 285px;
    width: 380px;
    padding: 1.3rem;
  }
}

@media screen and (max-width: 796px) {
  .about .container {
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .about h1,
  .team h1 {
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  }

  .about .container .mission {
    box-shadow: 0 0 20px 5px black;
  }

  .about .container .facility {
    box-shadow: 0 0 20px 5px black;
    order: 2;
  }

  .about .container .facilityImg {
    order: 1;
  }
}

@media screen and (max-width: 712px) {
  .team .card-container .card {
    margin: 15px 5px;
    border-radius: 0;
    width: 250px;
    height: 250px;
    padding: 5px;
  }

  .team .card-container .card .content .contentBox h2 {
    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 553px) {
  .team .card-container .card {
    margin: 8px 3px;
    border-radius: 0;
    width: 200px;
    height: 170px;
    padding: 5px;
  }

  .team .card-container .card .content .contentBox h2 {
    padding: 0px 5px;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
    font-size: 1rem;
  }

  .team .card-container .card .content .imgBox img {
    height: 140px;
  }
}

@media screen and (max-width: 475px) {
  .about,
  .team {
    padding: 6rem 0rem;
  }

  .about p {
    font-size: 1.05rem;
    line-height: 1.15rem;
  }

  .about .container .content h3 {
    font-size: 1.2rem;
    color: white;
  }

  .about .container {
    margin: 15px;
  }

  .about .container img {
    width: 105%;
    height: 300px;
    box-shadow: 0 0 0px 3px black;
  }

  .about .container .content {
    width: 105%;
    height: 250px;
    margin-top: 5px;
    padding: 0.8rem;
    box-shadow: 0 0 0px 3px black;
  }

  .team .card-container .card .content .contentBox h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 415px) {
  .team .card-container .card {
    width: 100%;
    height: auto;
    margin: 10px 0px;
  }
}
