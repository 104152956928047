.home {
  padding: 6rem 1rem;
  width: 100vw;
  min-height: 100vh;
  background: url("../../assets/background/space-bg.webp") no-repeat center center/cover;
  background-color: #1e1e1e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.quote {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.linked{
  text-decoration: underline !important;
}

.static-text {
  display: none;
}

.home .content {
  /* height: 700px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  text-align: center;
  padding-bottom: 2rem;
  z-index: 10;
}

.home h1 {
  cursor: pointer;
  font-size: 8rem;
  padding-top: 3.2rem;
  color: #f8f8f8;
  overflow: hidden;
  letter-spacing: 2px;
  font-family: "Chela One", "BalooBhaina-Regular";
  text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white !important;
  z-index: 10;
}

.home h1:hover {
  text-shadow: 0 0 12px white, 0 0 12px white, 0 0 12px white, 0 0 12px white !important;
}

.home h1:active {
  text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white !important;
}

.bouncing-text {
  display: flex;
}

.scroll-down {
  position: absolute;
  bottom: 30%;
  right: 10px;
}

.scroll-down svg {
  cursor: pointer;
  width: 50px;
  height: 50px;
  animation: bounce 1.7s ease infinite;
  -webkit-animation: bounce 1.7s ease infinite;
}

.scroll-down svg path {
  fill: var(--primary-color);
}

@media screen and (min-width: 688px) {
  .scroll-down {
    right: initial;
    left: 60px;
    bottom: 10%;
  }
}

.one {
  animation: bounce 1.7s ease infinite;
  -webkit-animation: bounce 1.7s ease infinite;
}

.two {
  animation: bounce 1.7s ease infinite 0.1s;
  -webkit-animation: bounce 1.7s ease infinite 0.1s;
}

.three {
  animation: bounce 1.7s ease infinite 0.2s;
  -webkit-animation: bounce 1.7s ease infinite 0.2s;
}

.four {
  animation: bounce 1.7s ease infinite 0.3s;
  -webkit-animation: bounce 1.7s ease infinite 0.3s;
}

.five {
  animation: bounce 1.7s ease infinite 0.4s;
  -webkit-animation: bounce 1.7s ease infinite 0.4s;
  margin-right: 5rem;
}

.six {
  animation: bounce 1.7s ease infinite 0.5s;
  -webkit-animation: bounce 1.7s ease infinite 0.5s;
}

.seven {
  animation: bounce 1.7s ease infinite 0.6s;
  -webkit-animation: bounce 1.7s ease infinite 0.6s;
}

.eight {
  animation: bounce 1.7s ease infinite 0.7s;
  -webkit-animation: bounce 1.7s ease infinite 0.7s;
}

.nine {
  animation: bounce 1.7s ease infinite 0.8s;
  -webkit-animation: bounce 1.7s ease infinite 0.8s;
}

.ten {
  animation: bounce 1.7s ease infinite 0.9s;
  -webkit-animation: bounce 1.7s ease infinite 0.9s;
}

.eleven {
  animation: bounce 1.7s ease infinite 1s;
  -webkit-animation: bounce 1.7s ease infinite 1s;
}

.twelve {
  animation: bounce 1.7s ease infinite 1.1s;
  -webkit-animation: bounce 1.7s ease infinite 1.1s;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.85, 1.1) translateY(-40px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  58% {
    transform: scale(1, 1) translateY(-7px);
  }

  65% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-40px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  58% {
    transform: scale(1, 1) translateY(-7px);
  }

  65% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* --------------------------------------------------------------- */

.home h2 {
  padding-bottom: 0.5rem;
}

.gradient-title {
  margin-top: 1rem;
  font-size: 5.8rem;
  background-image: radial-gradient(circle, #fed105, #f08519, #ff76d2, #5bc6f6 90%);
  background-size: 400%;
  animation: gradient 8s ease infinite;
  transition: 0.5s ease;
  -webkit-background-clip: text;
  color: transparent;
}

.gradient-message {
  letter-spacing: 3px;
  font-size: 2.5rem;
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black !important;
}

.home p {
  font-size: 1.7rem;
  padding: 0.5rem;
  color: white;
  z-index: 10;
}

.home button {
  font-size: 1.7rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.home .btn {
  z-index: 10;
}

.home .blue {
  color: var(--primary-color);
}

.home .orange {
  color: #f08519;
}

.home .green {
  color: #8eba24;
}

.home .yellow {
  color: #fed105;
}

.home .pink {
  color: #fb76d1;
}

.home .teal {
  color: #0cb1ab;
}

.home .lightpink {
  color: #fe9db0;
}

.home .hotpink {
  color: #fe407e;
}

.home .image img {
  height: auto;
  width: 900px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: hidden;
  z-index: 1;
}

.title {
  font-size: 6rem;
  color: #f8f8f8;
  padding: 5px 10px;
  overflow: hidden;
  text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black;
}

.title:hover {
  text-shadow: none !important;
  background-image: linear-gradient(
    90deg,
    rgba(255, 212, 1, 1) 15%,
    rgba(255, 145, 0, 1) 39%,
    rgba(255, 118, 210, 1) 61%,
    rgba(91, 198, 246, 1) 85%
  );
  -webkit-background-clip: text;
  color: transparent;
  transition: 0.2s all;
}

@media screen and (max-width: 475px) {
  .title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 2000px) {
  .home h1 {
    font-size: 10rem;
  }

  .home p {
    font-size: 2rem;
    padding: 1rem;
  }

  .home .content {
    height: 1000px;
  }

  .five {
    margin-right: 4rem;
  }

  .home button {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1100px) {
  .title:hover {
    transform: scale(1);
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .home h1 {
    font-size: 7rem;
  }

  .home {
    height: 90vh;
  }

  .home .content {
    height: 650px;
  }

  .five {
    margin-right: 3rem;
  }

  .home button {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 880px) {
  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }

    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
      transform: scale(0.9, 1.1) translateY(-20px);
    }

    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }

    58% {
      transform: scale(1, 1) translateY(-4px);
    }

    65% {
      transform: scale(1, 1) translateY(0);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }

    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
      transform: scale(0.9, 1.1) translateY(-20px);
    }

    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }

    58% {
      transform: scale(1, 1) translateY(-4px);
    }

    65% {
      transform: scale(1, 1) translateY(0);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  .home h1 {
    font-size: 5rem;
  }

  .gradient-title {
    margin-top: 1rem;
    font-size: 4.5rem;
  }

  .gradient-message {
    font-size: 2.5rem;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black !important;
  }

  .home p {
    font-size: 1.3rem;
  }

  .home .image img {
    width: 500px;
    transform: translate(-50%, 0);
  }

  .home button {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 688px) {
  .home h1 {
    font-size: 4rem;
  }

  .home button {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 525px) {
  .bouncing-text {
    display: none;
  }

  .static-text {
    display: block;
  }

  .home .image img {
    width: 100%;
    transform: translate(-50%, 0%);
  }

  .home h1 {
    font-size: 4.5rem !important;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white !important;
    padding-top: 1.5rem;
  }

  .home h1:hover {
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white !important;
  }

  .home h2 {
    font-size: 2rem;
    padding-bottom: 0.2rem;
  }

  .gradient-title {
    margin-top: 0.5rem;
    font-size: 3.7rem;
  }

  .gradient-message {
    font-size: 1.7rem;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black !important;
  }

  .quote {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 405px) and (max-height: 600px) {
  .home .image img {
    transform: translate(-50%, 10%);
  }
}

.location-cta {
  position: fixed;
  z-index: 100;
  right: 10px;
  bottom: 8px;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  border: 2px solid white;
  font-size: 1.1rem;
  background: radial-gradient(circle, #ffd401, #ff9100, #ff76d2, #5bc6f6 90%);
  background-size: 500%;
  animation: gradient 3s ease infinite;
  transition: 0.2s ease;
}

.location-cta:hover {
  box-shadow: none;
  border: 2px solid white;
  transform: scale(1.1);
  letter-spacing: initial;
}

.location-cta:hover svg {
  animation: locationIconBounce 0.3s ease infinite alternate;
}

.location-cta span {
  color: white;
  margin-bottom: 18px;
}

.location-cta svg {
  color: #f3cc6f;
  position: absolute;
  bottom: 6px;
  height: 25px;
}

@media screen and (min-width: 900px) {
  .location-cta {
    height: 7rem;
    width: 7rem;
    font-size: 1.3rem;
    bottom: 50%;
  }
}

@keyframes locationIconBounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}
